// src/GlobalStyles.js
import "../styles/PG4/variable.scss"
import "@fortawesome/fontawesome-free/css/all.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "reactjs-popup/dist/index.css";
import "../styles/App.css";
import "../styles/index.css";
import "../styles/header.css";
import "../styles/Login.css";
import "../styles/homepage.css";
import "../fonts/_fonts.scss";
import "../styles/main.css";
import "../styles/tablestyle.css";
import "../styles/PG4/pg4table.scss";
import "../styles/PG4/pg4navbar.scss";
import "../styles/PG4/actionsheet.scss"
import "../styles/PG4/slider.scss"
import "../styles/questionnaire.css";
import "../styles/chatTyper.css";
import "../styles/prsScore.css";
import "../styles/lifestyle.css";

const GlobalStyles = () => null;

export default GlobalStyles;
