import { createAction, RSAA } from "redux-api-middleware";

const protocol = process.env.REACT_APP_PROTOCOL;
const PG3_BASE_URL = process.env.REACT_APP_PG3_APP_BASE_DOMAIN;


export const PG4_REPORT_REQUEST = "PG4_REPORT_REQUEST";
export const PG4_REPORT_SUCCESS = "PG4_REPORT_SUCCESS";
export const PG4_REPORT_FAILURE = "PG4_REPORT_FAILURE";

export const SAVE_PATIENT_NOTES_REQUEST = "SAVE_PATIENT_NOTES_REQUEST";
export const SAVE_PATIENT_NOTES_SUCCESS = "SAVE_PATIENT_NOTES_SUCCESS";
export const SAVE_PATIENT_NOTES_FAILURE = "SAVE_PATIENT_NOTES_FAILURE";

export const RELEASE_TO_PATIENT_REQUEST = "RELEASE_TO_PATIENT_REQUEST";
export const RELEASE_TO_PATIENT_SUCCESS = "RELEASE_TO_PATIENT_SUCCESS";
export const RELEASE_TO_PATIENT_FAILURE = "RELEASE_TO_PATIENT_FAILURE";

export const PG4_REPORT_PATIENT_INFO_REQUEST = "PG4_REPORT_PATIENT_INFO_REQUEST";
export const PG4_REPORT_PATIENT_INFO_SUCCESS = "PG4_REPORT_PATIENT_INFO_SUCCESS";
export const PG4_REPORT_PATIENT_INFO_FAILURE = "PG4_REPORT_PATIENT_INFO_FAILURE";

export const PG4_REPORT_CARD_DETAIL_REQUEST = "PG4_REPORT_CARD_DETAIL_REQUEST";
export const PG4_REPORT_CARD_DETAIL_SUCCESS = "PG4_REPORT_CARD_DETAIL_SUCCESS";
export const PG4_REPORT_CARD_DETAIL_FAILURE = "PG4_REPORT_CARD_DETAIL_FAILURE";

export const PG4_DELETE_RECOMMENDATION_REQUEST = "PG4_DELETE_RECOMMENDATION_REQUEST";
export const PG4_DELETE_RECOMMENDATION_SUCCESS = "PG4_DELETE_RECOMMENDATION_SUCCESS";
export const PG4_DELETE_RECOMMENDATION_FAILURE = "PG4_DELETE_RECOMMENDATION_FAILURE";



export const CLEAR_STATE = "CLEAR_STATE";


export const updatePG4ReportData = (data) => {
    return {
        type: PG4_REPORT_SUCCESS,
        payload: data
    }
}

export const getPG4Report = (currentPracticeID, currentPractice, token, report_id) => {
    return createAction({
        // endpoint: `https://dev-azesytopghwzplj.api.raw-labs.com/report/new`,

        endpoint: `${protocol}//${currentPractice}${PG3_BASE_URL}/pg4_report?id=${report_id}`,
        method: "GET",
        headers: {
            Authorization: token,
            "Content-Type": "application/json",
            "Current-Practice": currentPracticeID
        },
        types: [
            PG4_REPORT_REQUEST,
            PG4_REPORT_SUCCESS,
            PG4_REPORT_FAILURE,
        ],
    });
};

export const savePatientNotes = ({ currentPracticeID, currentPractice, token, data }) => {
    return createAction({
        // endpoint: `https://jsonplaceholder.typicode.com/posts`,
        endpoint: `${protocol}//${currentPractice}${PG3_BASE_URL}/save_report_notes`,
        method: "POST",
        body: JSON.stringify(data),
        headers: {
            Authorization: token,
            "Current-Practice": currentPracticeID,
            "Content-Type": "application/json",
        },
        types: [
            SAVE_PATIENT_NOTES_REQUEST,
            SAVE_PATIENT_NOTES_SUCCESS,
            SAVE_PATIENT_NOTES_FAILURE,
        ],
    });
};


export const saveReorderRecommendation = ({ currentPracticeID, currentPractice, token, data }) => {
    return createAction({
        // endpoint: `https://jsonplaceholder.typicode.com/posts`,
        endpoint: `${protocol}//${currentPractice}${PG3_BASE_URL}/sort_report_recommendation`,
        method: "POST",
        body: JSON.stringify(data),
        headers: {
            Authorization: token,
            "Current-Practice": currentPracticeID,
            "Content-Type": "application/json",
        },
        types: [
            SAVE_PATIENT_NOTES_REQUEST,
            SAVE_PATIENT_NOTES_SUCCESS,
            SAVE_PATIENT_NOTES_FAILURE,
        ],
    });
};

export const createRecommendation = ({ currentPracticeID, currentPractice, token, data }) => {
    return createAction({
        // endpoint: `https://jsonplaceholder.typicode.com/posts`,
        endpoint: `${protocol}//${currentPractice}${PG3_BASE_URL}/add_custom_report_recommendation`,
        method: "POST",
        body: JSON.stringify(data),
        headers: {
            Authorization: token,
            "Current-Practice": currentPracticeID,
            "Content-Type": "application/json",
        },
        types: [
            SAVE_PATIENT_NOTES_REQUEST,
            SAVE_PATIENT_NOTES_SUCCESS,
            SAVE_PATIENT_NOTES_FAILURE,
        ],
    });
};

export const releaseReportToPatient = ({ currentPracticeID, currentPractice, token, data }) => {
    return createAction({
        // endpoint: `https://dev-azesytopghwzplj.api.raw-labs.com/report/new`,
        endpoint: `${protocol}//${currentPractice}${PG3_BASE_URL}/share_report`,
        method: "POST",
        body: JSON.stringify(data),
        headers: {
            Authorization: token,
            "Current-Practice": currentPracticeID,
            "Content-Type": "application/json",
            // "ngrok-skip-browser-warning": "69420"
        },
        types: [
            RELEASE_TO_PATIENT_REQUEST,
            RELEASE_TO_PATIENT_SUCCESS,
            RELEASE_TO_PATIENT_FAILURE,
        ],
    });
};



export const getFocusCardDetail = ({ currentPracticeID, currentPractice, token, report_id, focus_id }) => {
    return createAction({
        // endpoint: `https://dev-azesytopghwzplj.api.raw-labs.com/focus/detail/123`,
        endpoint: `${protocol}//${currentPractice}${PG3_BASE_URL}/pg4_report_focus_area_detail`,
        method: "POST",
        body: JSON.stringify({ 'id': report_id, 'focus_id': focus_id }),
        headers: {
            Authorization: token,
            "Current-Practice": currentPracticeID,
            "Content-Type": "application/json",
        },
        types: [
            PG4_REPORT_CARD_DETAIL_REQUEST,
            PG4_REPORT_CARD_DETAIL_SUCCESS,
            PG4_REPORT_CARD_DETAIL_FAILURE,
        ],
    });
};


export const getMSQResponses = ({ currentPracticeID, currentPractice, token, report_id }) => {
    return createAction({
        // endpoint: `https://dev-azesytopghwzplj.api.raw-labs.com/msq/responses/${report_id}`,
        endpoint: `${protocol}//${currentPractice}${PG3_BASE_URL}/get_report_questionnaire_results?report_id=${report_id}`,
        method: "GET",
        headers: {
            Authorization: token,
            "Current-Practice": currentPracticeID,
            "Content-Type": "application/json"
        },
        types: [
            PG4_REPORT_CARD_DETAIL_REQUEST,
            PG4_REPORT_CARD_DETAIL_SUCCESS,
            PG4_REPORT_CARD_DETAIL_FAILURE,
        ],
    });
};


export const deleteRecommendation = ({ currentPracticeID, currentPractice, token, data }) => {
    return createAction({
        // endpoint: `https://dev-azesytopghwzplj.api.raw-labs.com/msq/responses/${report_id}`,
        endpoint: `${protocol}//${currentPractice}${PG3_BASE_URL}/delete_report_recommendation`,
        method: "POST",
        body: JSON.stringify(data),
        headers: {
            Authorization: token,
            "Current-Practice": currentPracticeID,
            "Content-Type": "application/json",
        },
        types: [
            PG4_DELETE_RECOMMENDATION_REQUEST,
            PG4_DELETE_RECOMMENDATION_SUCCESS,
            PG4_DELETE_RECOMMENDATION_FAILURE,
        ],
    });
};


export const selectRecommendation = ({ currentPracticeID, currentPractice, token, data }) => {
    return createAction({
        // endpoint: `https://dev-azesytopghwzplj.api.raw-labs.com/msq/responses/${report_id}`,
        endpoint: `${protocol}//${currentPractice}${PG3_BASE_URL}/toggle_report_recommendations`,
        method: "POST",
        body: JSON.stringify(data),
        headers: {
            Authorization: token,
            "Current-Practice": currentPracticeID,
            "Content-Type": "application/json",
        },
        types: [
            PG4_DELETE_RECOMMENDATION_REQUEST,
            PG4_DELETE_RECOMMENDATION_SUCCESS,
            PG4_DELETE_RECOMMENDATION_FAILURE,
        ],
    });
};

