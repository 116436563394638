import React, { Suspense } from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { HistoryRouter } from "redux-first-history/rr6";
import { Toaster } from 'react-hot-toast';
// import ReactGA from 'react-ga';

import App from "./App";
import history from "./history";
import configureStore from "./store";
import ScrollToTop from "./components/ScrollToTop";
import { Loading } from "./components/Loading"
import { createRoot } from 'react-dom/client';
import "./translation/i18n"
import SEO from "./components/SEO";
import GlobalStyles from "./components/GlobalStyles";

export const store = configureStore(history);

const container = document.getElementById('root');
const root = createRoot(container);

const _AppComponent = <Suspense fallback={<Loading />}>
  <Provider store={store}>
    <HistoryRouter history={history}>
      <ScrollToTop />
      <div>
        <GlobalStyles />
        <SEO />
        <Toaster
          position="top-right"
          toastOptions={{ duration: 6000 }}
          reverseOrder={true}
        />
        <App />
      </div>
    </HistoryRouter>
  </Provider>
</Suspense>;


root.render(_AppComponent);