import { createAction } from "redux-api-middleware";

const protocol = process.env.REACT_APP_PROTOCOL;
const baseUrl = process.env.REACT_APP_PG3_APP_DOMAIN;

export const ADD_PRACTITIONER_REQUEST = "ADD_PRACTITIONER_REQUEST";
export const ADD_PRACTITIONER_SUCCESS = "ADD_PRACTITIONER_SUCCESS";
export const ADD_PRACTITIONER_FAILURE = "ADD_PRACTITIONER_FAILURE";

export const GET_PRACTITIONERS_REQUEST = "GET_PRACTITIONERS_REQUEST";
export const GET_PRACTITIONERS_SUCCESS = "GET_PRACTITIONERS_SUCCESS";
export const GET_PRACTITIONERS_FAILURE = "GET_PRACTITIONERS_FAILURE";

export const GET_PRACTITIONERS_LIST_REQUEST = "GET_PRACTITIONERS_LIST_REQUEST";
export const GET_PRACTITIONERS_LIST_SUCCESS = "GET_PRACTITIONERS_LIST_SUCCESS";
export const GET_PRACTITIONERS_LIST_FAILURE = "GET_PRACTITIONERS_LIST_FAILURE";

export const BATCH_FUNCTION_REQUEST = "BATCH_FUNCTION_REQUEST";
export const BATCH_FUNCTION_SUCCESS = "BATCH_FUNCTION_SUCCESS";
export const BATCH_FUNCTION_FAILURE = "BATCH_FUNCTION_FAILURE";

export const GET_PRACTITIONER_TYPES_REQUEST = "GET_PRACTITIONER_TYPES_REQUEST";
export const GET_PRACTITIONER_TYPES_SUCCESS = "GET_PRACTITIONER_TYPES_SUCCESS";
export const GET_PRACTITIONER_TYPES_FAILURE = "GET_PRACTITIONER_TYPES_FAILURE";

export const PRACTITIONER_GDPR_REQUEST = "PRACTITIONER_GDPR_REQUEST";
export const PRACTITIONER_GDPR_REQUEST_SUCCESS = "PRACTITIONER_GDPR_REQUEST_SUCCESS";
export const PRACTITIONER_GDPR_REQUEST_FAILURE = "PRACTITIONER_GDPR_REQUEST_FAILURE";

export const getPractitioners = (
  token, url
) => {
  return createAction({
    endpoint: `${baseUrl}/admin/practitioners?${url}`,
    method: "GET",
    headers: { Authorization: token, "User-Type": "Admin" },
    types: [
      GET_PRACTITIONERS_REQUEST,
      GET_PRACTITIONERS_SUCCESS,
      GET_PRACTITIONERS_FAILURE,
    ],
  });
};

export const getPractitionersList = (
  token, country = "United States"
) => {
  const baseUrl = process.env.REACT_APP_PG3_APP_DOMAIN;
  return createAction({
    endpoint: `${baseUrl}/fetch_practitioners?country=${country}`,
    method: "GET",
    headers: { Authorization: token, "User-Type": "Admin", "Content-Type": "application/json" },
    types: [
      GET_PRACTITIONERS_LIST_REQUEST,
      GET_PRACTITIONERS_LIST_SUCCESS,
      GET_PRACTITIONERS_LIST_FAILURE,
    ],
  });
};

export const addPractitioner = (token, data) => {

  const protocol = process.env.REACT_APP_PROTOCOL;
  const baseUrl = process.env.REACT_APP_PG3_APP_DOMAIN;
  return createAction({
    endpoint: `${baseUrl}/admin/practitioners`,
    body: JSON.stringify(data),
    method: "POST",
    headers: { Authorization: token, "User-Type": "Admin", "Content-Type": "application/json" },
    types: [
      ADD_PRACTITIONER_REQUEST,
      ADD_PRACTITIONER_SUCCESS,
      ADD_PRACTITIONER_FAILURE,
    ],
  });
};

export const getPractitioner = (token, id) => {
  return createAction({
    endpoint: `${baseUrl}/admin/practitioners/${id}`,
    method: "GET",
    headers: { Authorization: token, "User-Type": "Admin", "Content-Type": "application/json" },
    types: [
      BATCH_FUNCTION_REQUEST,
      BATCH_FUNCTION_SUCCESS,
      BATCH_FUNCTION_FAILURE,
    ],
  });
};

export const editPractitioner = (token, id, data) => {
  return createAction({
    endpoint: `${baseUrl}/admin/practitioners/${id}`,
    body: JSON.stringify(data),
    method: "PATCH",
    headers: { Authorization: token, "User-Type": "Admin", "Content-Type": "application/json" },
    types: [
      BATCH_FUNCTION_REQUEST,
      BATCH_FUNCTION_SUCCESS,
      BATCH_FUNCTION_FAILURE,
    ],
  });
};

export const resetPassword = (token, ids) => {
  const data = {
    ids
  };
  return createAction({
    endpoint: `${baseUrl}/admin/user/reset_password`,
    body: JSON.stringify(data),
    method: "PATCH",
    headers: { Authorization: token, "User-Type": "Admin", "Content-Type": "application/json" },
    types: [
      BATCH_FUNCTION_REQUEST,
      BATCH_FUNCTION_SUCCESS,
      BATCH_FUNCTION_FAILURE,
    ],
  });
};

export const resetLockout = (token, ids) => {
  const data = {
    ids
  };
  return createAction({
    endpoint: `${baseUrl}/admin/user/reset_lockout`,
    body: JSON.stringify(data),
    method: "PATCH",
    headers: { Authorization: token, "User-Type": "Admin", "Content-Type": "application/json" },
    types: [
      BATCH_FUNCTION_REQUEST,
      BATCH_FUNCTION_SUCCESS,
      BATCH_FUNCTION_FAILURE,
    ],
  });
};

export const archivePractitioners = (token, ids) => {
  const data = {
    ids
  };
  return createAction({
    endpoint: `${baseUrl}/admin/user/archive`,
    body: JSON.stringify(data),
    method: "PATCH",
    headers: { Authorization: token, "User-Type": "Admin", "Content-Type": "application/json" },
    types: [
      BATCH_FUNCTION_REQUEST,
      BATCH_FUNCTION_SUCCESS,
      BATCH_FUNCTION_FAILURE,
    ],
  });
};

export const restorePractitioners = (token, ids) => {
  const data = {
    ids
  };
  return createAction({
    endpoint: `${baseUrl}/admin/user/restore`,
    body: JSON.stringify(data),
    method: "PATCH",
    headers: { Authorization: token, "User-Type": "Admin", "Content-Type": "application/json" },
    types: [
      BATCH_FUNCTION_REQUEST,
      BATCH_FUNCTION_SUCCESS,
      BATCH_FUNCTION_FAILURE,
    ],
  });
};

export const getPractitionerTypes = (
  token
) => {
  const baseUrl = process.env.REACT_APP_PG3_APP_DOMAIN;
  return createAction({
    endpoint: `${baseUrl}/fetch_practitioner_types`,
    method: "GET",
    headers: { Authorization: token, "User-Type": "Admin", "Content-Type": "application/json" },
    types: [
      GET_PRACTITIONER_TYPES_REQUEST,
      GET_PRACTITIONER_TYPES_SUCCESS,
      GET_PRACTITIONER_TYPES_FAILURE,],
  });
};

export const approvePractitioners = (token, ids) => {
  const data = {
    ids
  };
  return createAction({
    endpoint: `${baseUrl}/admin/user/approve`,
    body: JSON.stringify(data),
    method: "PATCH",
    headers: { Authorization: token, "User-Type": "Admin", "Content-Type": "application/json" },
    types: [
      BATCH_FUNCTION_REQUEST,
      BATCH_FUNCTION_SUCCESS,
      BATCH_FUNCTION_FAILURE,
    ],
  });
};

export const sendRegistrationReminder = (token, ids) => {
  const data = {
    ids
  };
  return createAction({
    endpoint: `${baseUrl}/admin/user/send_registration_reminder`,
    body: JSON.stringify(data),
    method: "POST",
    headers: { Authorization: token, "User-Type": "Admin", "Content-Type": "application/json" },
    types: [
      BATCH_FUNCTION_REQUEST,
      BATCH_FUNCTION_SUCCESS,
      BATCH_FUNCTION_FAILURE,
    ],
  });
};

export const getPracticeGDPRRequestLogForAdmin = (token, pID) => {
  return createAction({
    endpoint: `${baseUrl}/admin/practitioner_gdpr_list?id=${pID}`,
    method: "GET",
    headers: { "Content-Type": "application/json", Authorization: token, },
    types: [
      BATCH_FUNCTION_REQUEST,
      BATCH_FUNCTION_SUCCESS,
      BATCH_FUNCTION_FAILURE,
    ],
  });
}

export const gdprRequest = (token, id, requestType) => {
  const data = {
    id: id,
    request_type: requestType
  };
  return createAction({
    endpoint: `${baseUrl}/admin/practitioner/gdpr_request`,
    body: JSON.stringify(data),
    method: "POST",
    headers: { Authorization: token, "User-Type": "Admin", "Content-Type": "application/json" },
    types: [
      PRACTITIONER_GDPR_REQUEST,
      PRACTITIONER_GDPR_REQUEST_SUCCESS,
      PRACTITIONER_GDPR_REQUEST_FAILURE,
    ],
  });
};



export const getPractitionerCSVReportTable = (token, url) => {
  return createAction({
    endpoint: `${baseUrl}/admin/admin_reports?${url}`,
    method: "GET",
    headers: { "Content-Type": "application/json", Authorization: token, },
    types: [
      BATCH_FUNCTION_REQUEST,
      BATCH_FUNCTION_SUCCESS,
      BATCH_FUNCTION_FAILURE,
    ],
  });
}

export const requestPractitionerCSV = (token, url) => {
  return createAction({
    endpoint: `${baseUrl}/admin/user/download_csv${url}`,
    method: "GET",
    headers: { "Content-Type": "application/json", Authorization: token, },
    types: [
      BATCH_FUNCTION_REQUEST,
      BATCH_FUNCTION_SUCCESS,
      BATCH_FUNCTION_FAILURE,
    ],
  });
}
