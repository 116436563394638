import React from "react";
import { Loading } from "../components/Loading";
import Cookies from "universal-cookie";
import { useSelector } from "react-redux";
import { Outlet, Navigate, useLocation } from "react-router-dom";

const cookies = new Cookies();

export const AdminProtectedRoute = ({
  isAuth: isAuthenticated,
  mfaEnabled,
  userType,
  inHomeApp,
}) => {
  if (mfaEnabled && isAuthenticated && userType === "AdminUser") {
    return <Outlet />;
  }
  else return <Navigate to={{ pathname: "/users/login" }} />

};

export const PracticeProtectedRoute = ({
  isAuth: isAuthenticated,
  mfaEnabled,
  userType,
  inHomeApp,
}) => {
  const currentDomain = useSelector((state) => state.auth.currentPractice);
  const location = useLocation();
  if (mfaEnabled && isAuthenticated && userType === "User") {
    console.log("insde if of pr route");
    // inHomeApp ? <Loading /> :
    return (
      <Outlet location={location} />
    );
  } else {
    console.log("insde else of pr1 route");
    return (
      <Navigate
        to={{ pathname: "/users/login" }}
      />
    );
  }
}

export const LoginRoute = ({
  isAuth: isAuthenticated,
  mfaEnabled,
  userType,
  inHomeApp,
  ...rest
}) => {
  if (mfaEnabled && isAuthenticated) {
    if (userType === "User")
      return (
        <Navigate
          to={{
            pathname: "/practice/dashboard",
            // state: { from: props.location },
          }}
        />
      );
    else if (userType === "Patient")
      return (
        <Navigate
          to={{
            pathname: "/patient/dashboard",

          }}
        />
      );
    else if (userType === "AdminUser")
      return (
        <Navigate
          to={{
            pathname: "/admin/dashboard",

          }}
        />
      );
  } else
    return cookies.get("isAuthenticated") ? (
      <Loading />
    ) : (
      <Outlet />
    );

}

export const MFARoute = ({
  isAuth: isAuthenticated,
  mfaEnabled,
  userType,
  inHomeApp,
}) => {
  if (mfaEnabled && isAuthenticated) {
    console.log("mfaEnabled");
    console.log(mfaEnabled);
    if (userType === "User")
      return (
        <Navigate
          to={{
            pathname: "/practice/dashboard"
          }}
        />
      );
    else if (userType === "Patient")
      return (
        <Navigate
          to={{
            pathname: "/patient/dashboard"
          }}
        />
      );
    else if (userType === "AdminUser")
      return (
        <Navigate
          to={{
            pathname: "/admin/dashboard"
          }}
        />
      );
  }
  else {
    console.log("wwwww");
    console.log(mfaEnabled);
    return (
      <Outlet />
    );
  }
};
export const PatientRoute = ({
  isAuth: isAuthenticated,
  mfaEnabled,
  userType,
  inHomeApp,
}) => {
  return mfaEnabled && isAuthenticated && userType === "Patient" ? (
    inHomeApp ? (
      <Loading />
    ) : (
      <Outlet />
    )
  ) : (
    <Navigate
      to={{ pathname: "/users/login" }}
    />
  )

}
