import { useEffect, useState } from "react";

export const LogoutAlert = ({ onContinue, onDismiss, remainingSeconds }) => {
    const [counter, setCounter] = useState(remainingSeconds);
    const [timer, setTimer] = useState("");

    useEffect(() => {
        console.log(counter);
        if (counter < 1) {
            clearInterval(timer);
            onDismiss();
        }
    }, [counter]);

    useEffect(() => {
        showCounter(counter);
    }, []);

    const showCounter = (counter) => {
        setTimer(
            setInterval(() => {
                setCounter((c) => --c);
            }, 1000)
        );
    };

    return (
        <span>
            <p>Your session will be logged out in {counter} seconds!</p>
            <button
                className="btn btn-outline px-5 my-3"
                onClick={() => onContinue()}
            >
                Continue to stay logged in
            </button>
        </span>
    );
};