import { createAction } from "redux-api-middleware";

const BASE_URL = process.env.REACT_APP_PG3_APP_DOMAIN;

export const AUTH_REQUEST = "AUTH_REQUEST";
export const AUTH_SUCCESS = "AUTH_SUCCESS";
export const AUTH_FAILURE = "AUTH_FAILURE";
export const FORGOT_EMAIL_SENT_SUCCESS = "FORGOT_EMAIL_SENT_SUCCESS";
export const REFRESH_TOKEN_REQUEST = "REFRESH_TOKEN_REQUEST";
export const REFRESH_TOKEN_SUCCESS = "REFRESH_TOKEN_SUCCESS";
export const REFRESH_TOKEN_FAILURE = "REFRESH_TOKEN_FAILURE";

export const AGREED_TERMS_REQUEST = "AGREED_TERMS_REQUEST";
export const AGREED_TERMS_SUCCESS = "AGREED_TERMS_SUCCESS";
export const AGREED_TERMS_FAILURE = "AGREED_TERMS_FAILURE";
export const ADD_PRACTITIONER_REQUEST = "ADD_PRACTITIONER_REQUEST";
export const ADD_PRACTITIONER_SUCCESS = "ADD_PRACTITIONER_SUCCESS";
export const ADD_PRACTITIONER_FAILURE = "ADD_PRACTITIONER_FAILURE";
export const TOGGLE_DOB_BANNER_STATE = "TOGGLE_DOB_BANNER_STATE";

export const REG_PAN_REQUEST = "REG_PAN_REQUEST";
export const REG_PAN_SUCCESS = "REG_PAN_SUCCESS";
export const REG_PAN_FAILURE = "REG_PAN_FAILURE";

export const LOGOUT = "LOGOUT";
export const CHANGE_IS_AUTH = "CHANGE_IS_AUTH";

export const authenticate = (userEmail, password, auth_code) => {
  let params = {
    user: {
      email: userEmail.trim(),
      password: password,
    },
    auth_code: auth_code
  };
  return createAction({
    endpoint: BASE_URL + `/login/`,
    method: "POST",
    body: JSON.stringify(params),
    headers: { "Content-Type": "application/json" },
    types: [
      AUTH_REQUEST,
      {
        type: AUTH_SUCCESS,
        payload: (action, state, res) => {
          const authToken = res.headers.get("Authorization");
          return res.json().then((json) => {
            return { ...json, token: authToken };
          });
        },
      },
      AUTH_FAILURE,
    ],
  });
};

export const changeIsAuth = (payload) => ({
  type: CHANGE_IS_AUTH,
  payload,
});

export const __logout = (token, currentPractice, currentPracticeID) => {
  const protocol = process.env.REACT_APP_PROTOCOL;
  const PG3_BASE_URL = process.env.REACT_APP_PG3_APP_BASE_DOMAIN;
  return createAction({
    endpoint: `${protocol}//${currentPractice}${PG3_BASE_URL}/logout`,
    // endpoint: BASE_URL + `/logout`,
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
      Authorization: token,
      "Current-Practice": currentPracticeID,
    },
    types: [LOGOUT, LOGOUT, LOGOUT],
  });
};

export const refreshToken = (token, currentPractice, currentPracticeID) => {
  const protocol = process.env.REACT_APP_PROTOCOL;
  const PG3_BASE_URL = process.env.REACT_APP_PG3_APP_BASE_DOMAIN;
  return createAction({
    endpoint: `${protocol}//${currentPractice}${PG3_BASE_URL}/refresh_token`,
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: token,
      "Current-Practice": currentPracticeID,
    },
    types: [
      REFRESH_TOKEN_REQUEST,
      {
        type: REFRESH_TOKEN_SUCCESS,
        payload: (action, state, res) => {
          const authToken = res.headers.get("Authorization");
          return res.json().then((json) => {
            return { ...json, token: authToken };
          });
        },
      },
      REFRESH_TOKEN_FAILURE,
    ],
  });
};

export const verifyOtp = (id, userType, otp) => {
  let params = {
    otp,
  };
  return createAction({
    endpoint: BASE_URL + `/${id}/${userType}/verify_otp/`,
    method: "POST",
    body: JSON.stringify(params),
    headers: { "Content-Type": "application/json" },
    types: [AUTH_REQUEST, FORGOT_EMAIL_SENT_SUCCESS, AUTH_FAILURE],
  });
};

export const addPractitioner = (data) => {
  const protocol = process.env.REACT_APP_PROTOCOL;
  const baseUrl = process.env.REACT_APP_PG3_APP_DOMAIN;
  return createAction({
    endpoint: `${baseUrl}/create_account`,
    body: JSON.stringify(data),
    method: "POST",
    headers: { "Content-Type": "application/json" },
    types: [
      ADD_PRACTITIONER_REQUEST,
      ADD_PRACTITIONER_SUCCESS,
      ADD_PRACTITIONER_FAILURE,
    ],
  });
};

export const registerPureAccountCall = (data) => {
  const protocol = process.env.REACT_APP_PROTOCOL;
  const baseUrl = process.env.REACT_APP_PG3_APP_DOMAIN; 
  return createAction({
    endpoint: `${baseUrl}/fetch_pure_account_details`,
    body: JSON.stringify(data),
    method: "POST",
    headers: { "Content-Type": "application/json" },
    types: [
      REG_PAN_REQUEST,
      REG_PAN_SUCCESS,
      REG_PAN_FAILURE,
    ],
  });
};


export const resendOtp = (id, userType) => {
  return createAction({
    endpoint: BASE_URL + `/${id}/${userType}/resend_otp/`,
    method: "POST",
    headers: { "Content-Type": "application/json" },
    types: [AUTH_REQUEST, FORGOT_EMAIL_SENT_SUCCESS, AUTH_FAILURE],
  });
};

export const sendOTPForMultiDomain = (id, practiceID) => {
  return createAction({
    endpoint: BASE_URL + `/${practiceID}/multi_domain/${id}`,
    method: "POST",
    headers: { "Content-Type": "application/json" },
    types: [AUTH_REQUEST, FORGOT_EMAIL_SENT_SUCCESS, AUTH_FAILURE],
  });
};

export const forgotPassword = (email) => {
  let params = {
    email,
  };
  return createAction({
    endpoint: BASE_URL + `/password/`,
    method: "POST",
    body: JSON.stringify(params),
    headers: { "Content-Type": "application/json" },
    types: [AUTH_REQUEST, FORGOT_EMAIL_SENT_SUCCESS, AUTH_FAILURE],
  });
};

export const acceptTermsConditions = (
  token,
  currentPracticeID,
  currentPractice,
  type
) => {
  const protocol = process.env.REACT_APP_PROTOCOL;
  const PG3_BASE_URL = process.env.REACT_APP_PG3_APP_BASE_DOMAIN;
  return createAction({
    endpoint: `${protocol}//${currentPractice}${PG3_BASE_URL}/practice/terms_and_condition/${type}/${currentPracticeID}`,
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Authorization: token,
      "Current-Practice": currentPracticeID,
    },
    types: [AGREED_TERMS_REQUEST, AGREED_TERMS_SUCCESS, AGREED_TERMS_FAILURE],
  });
};

// export const sendResetPassword=()=>{

export const contactUsForm = (data) => {
  return createAction({
    endpoint: BASE_URL + `/contact_us`,
    method: "POST",
    body: JSON.stringify(data),
    headers: { "Content-Type": "application/json" },
    types: [
      ADD_PRACTITIONER_REQUEST,
      ADD_PRACTITIONER_SUCCESS,
      ADD_PRACTITIONER_FAILURE,
    ],
  });
};

export const postLogin = (token, currentPracticeID) => {
  return createAction({
    endpoint: BASE_URL + `/practice/post_login/${currentPracticeID}`,
    method: "POST",
    headers: { "Content-Type": "application/json", "Current-Practice": currentPracticeID },
    types: [
      ADD_PRACTITIONER_REQUEST,
      ADD_PRACTITIONER_SUCCESS,
      ADD_PRACTITIONER_FAILURE,
    ],
  });
};

export const updatePassword = ({ data }) => {
  return createAction({
    endpoint: BASE_URL + `/update_expired_password`,
    method: "POST",
    body: JSON.stringify(data),
    headers: { "Content-Type": "application/json" },
    types: [
      ADD_PRACTITIONER_REQUEST,
      ADD_PRACTITIONER_SUCCESS,
      ADD_PRACTITIONER_FAILURE,
    ],
  });
};
