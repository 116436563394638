import React from "react";
import { Helmet } from "react-helmet";
import ogImage from '../assets/pg4/rebranding/pi_logo.png';

const metaTags = [
    { name: "description", content: "PureInsight™ is a complimentary nutrigenomics service that empowers individuals with simple, reliable, and actionable nutrition and lifestyle recommendations." },
    { name: "keywords", content: "DNA, Multiple Symptom Questionnaire, Lab report, DNA analysis, nutrigenomics, nutrition recommendations, lifestyle recommendations" },
    { name: "viewport", content: "width=device-width, initial-scale=1.0" },
    { httpEquiv: "Content-Type", content: "text/html; charset=UTF-8" },
    { name: "author", content: "PureGenomics" },
    { name: "robots", content: "index, follow" },
    { name: "twitter:card", content: "summary_large_image" },
    { name: "twitter:site", content: "@pureencaps" },
    { name: "twitter:title", content: "PureINsight" },
    { name: "twitter:description", content: "PureInsight™ is a complimentary nutrigenomics service that empowers individuals with simple, reliable, and actionable nutrition and lifestyle recommendations." },
    { name: "twitter:image", content: ogImage },
    { property: "og:title", content: "PureINsight" },
    { property: "og:description", content: "PureInsight™ is a complimentary nutrigenomics service that empowers individuals with simple, reliable, and actionable nutrition and lifestyle recommendations." },
    { property: "og:type", content: "website" },
    { property: "og:url", content: "https://puregenomics.com" },
    { property: "og:image", content: ogImage },
    { property: "og:site_name", content: "PureInsight™" },
];

const alternateLinks = [
    { href: "https://puregenomics.com", hreflang: "en-us" },
    { href: "https://puregenomics.ca", hreflang: "en-ca" },
    { href: "https://puregenomics.co.uk", hreflang: "en-gb" },
    { href: "https://puregenomics.co.uk", hreflang: "en-ie" },
];

function SEO() {
    return (
        <Helmet>
            <title>PureInsight™</title>
            {metaTags.map((tag, index) => {
                if (tag.name) {
                    return <meta key={index} name={tag.name} content={tag.content} />;
                } else if (tag.property) {
                    return <meta key={index} property={tag.property} content={tag.content} />;
                } else if (tag.httpEquiv) {
                    return <meta key={index} httpEquiv={tag.httpEquiv} content={tag.content} />;
                }
                return null;
            })}
            {alternateLinks.map((link, index) => (
                <link key={index} rel="alternate" href={link.href} hreflang={link.hreflang} />
            ))}
        </Helmet>
    );
}

export default SEO;
