import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import Cookies from "universal-cookie";
import _ from "lodash";
import ChainedBackend from "i18next-chained-backend";
import HttpBackend from "i18next-http-backend";
import resourcesToBackend from "i18next-resources-to-backend";
import LanguageDetector from "i18next-browser-languagedetector";
import qs from "query-string";

const REACT_APP_PG3_APP_DOMAIN = process.env.REACT_APP_PG3_APP_DOMAIN;
const cookies = new Cookies();
const obj = qs.parse(window.location.search, { decode: false });

if (!_.isEmpty(obj) && obj.lng) {
  cookies.set("i18next", obj.lng);
}

if (window.location.hostname.endsWith(".co.uk"))
  cookies.set("i18next", "en_gb");

if (window.location.hostname.endsWith(".ca")) cookies.set("i18next", "ca");

if (cookies.get("i18next") == "undefined") {
  cookies.set("i18next", "en");
  console.warn("setting i18 to en");
  var i18nextValue = "en";
} else if (cookies.get("i18next") == undefined) {
  cookies.set("i18next", "en");
  console.warn("setting i18 to en");
  var i18nextValue = "en";
} else {
  var i18nextValue = cookies.get("i18next");
}

i18n
  .use(ChainedBackend)
  .use(initReactI18next)
  .use(LanguageDetector)
  .init({
    fallbackLng: "en",
    saveMissing: false,
    initImmediate: true,
    keySeparator: false,
    interpolation: {
      escapeValue: false,
    },
    nonExplicitSupportedLngs: true,
    debug: true,
    react: {
      useSuspense: true,
    },
    backend: {
      backends: [
        HttpBackend,
        resourcesToBackend((lng, ns) =>
          import(`../../public/locales/${lng}/${ns}.json`)
        ),
      ],
      backendOptions: [
        {
          loadPath: `${REACT_APP_PG3_APP_DOMAIN}/language_text?lang={{lng}}`,
          crossDomain: true,
        },
        {
          loadPath: "/locales/{{lng}}/translation.json",
        },
      ],
    },
  });

export default i18n;
