import React from "react";
import { useNavigate } from "react-router-dom";
import { Dropdown } from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";

import { LOGOUT, __logout } from "../../store/actions/auth";
import { ReactComponent as PgLogo } from "../../assets/PG_Logo.svg";
import { ReactComponent as PiLogo } from "../../assets/PureInsight_Logo_RGB_u1.svg";
import { clearCookie } from "../../methods";
import { toggleSidebar } from "../../methods/sidenav";

function Header() {
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const isAuth = useSelector((state) => state.auth.isAuth);
  const firstName = useSelector((state) => state.auth.firstName);
  const lastName = useSelector((state) => state.auth.lastName);
  const practices = useSelector((state) => state.auth.practices);
  const userType = useSelector((state) => state.auth.userType);
  const token = useSelector((state) => state.auth.token);
  const currentPractice = useSelector((state) => state.auth.currentPractice);
  const currentPracticeID = useSelector(
    (state) => state.auth.currentPracticeID
  );

  const logout = () => {
    dispatch(__logout(token, currentPractice, currentPracticeID));
    clearCookie();
    localStorage.clear();
    window.location.href = `${window.location.origin}/users/login`;
  };

  return (
    <nav
      className="container-fluid mx-0 navbar py-3 no-print"
      style={{ borderBottom: "40px solid var(--pg_primary)" }}
    >
      <div className="container-xl  px-0">
        {/* menu button */}
        <div className="icon-one d-md-none">
          <div
            className="hamburger hamburger-one"
            onClick={toggleSidebar}
          ></div>
        </div>

        {/* Logo */}
        <div
          className="m-auto m-md-0 cursor-pointer pg-logo"
          onClick={() => navigate("/admin/dashboard")}
        >

          {i18n.language == "en_gb" ? <PgLogo fill="#fff" fontSize="100px" /> : <PiLogo fill="#fff" fontSize="100px" />}
        </div>
        {isAuth && (
          <div>
            <span
              className="cursor-pointer pg-text__bold text-decoration-none textc-primary mx-2 d-none d-lg-inline-block"
              style={{ verticalAlign: "top" }}
            >
              {firstName + " " + lastName}
            </span>
            <Dropdown className="d-inline-block border-radius">
              <Dropdown.Toggle as="i" bsPrefix="text-danger">
                <i className="fas fa-user-cog cursor-pointer user-icon textc-primary"></i>
              </Dropdown.Toggle>
              <Dropdown.Menu className="border-radius">
                <Dropdown.Item onClick={() => logout()}>
                  {" "}
                  <i
                    className="fas fa-sign-out-alt"
                    style={{ color: "var(--pg_primary)", fontSize: 25 }}
                  ></i>{" "}
                  <span style={{ verticalAlign: "top" }}>Logout</span>
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </div>
        )}
      </div>
    </nav>
  );
}
export default Header;
