import { jsPDF } from "jspdf";
import html2canvas from "html2canvas";
import _ from "lodash";
import toastr from "react-hot-toast";

export const clearCookie = () => {
  var cookies = document.cookie.split("; ");
  console.log(cookies, "-=-=-=--cookies");
  for (var c = 0; c < cookies.length; c++) {
    var d = window.location.hostname.split(".");


    console.log(cookies[c].split(";")[0].split("=")[0], "<<---", "condition");
    while (d.length > 0) {

      var cookieBase =
        encodeURIComponent(cookies[c].split(";")[0].split("=")[0]) +
        "=; expires=Thu, 01-Jan-1970 00:00:01 GMT; domain=" +
        d.join(".") +
        " ;path=";
      var p = window.location.pathname.split("/");
      document.cookie = cookieBase + "/";
      while (p.length > 0) {
        document.cookie = cookieBase + p.join("/");
        p.pop();
      }
      d.shift();
    }


  }
};

export const validateEmail = (val) => {
  const emailPattern = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return emailPattern.test(val);
};

export const isPdfFile = (file) => {
  return file.type !== "application/pdf";
};

export const isPdfFiles = (files) => {
  return _.filter(files, (file) => file.type !== "application/pdf").length > 0;
};

export const validatePassword = (val) => val.length > 0;

export const getFinishIcon = () => {
  return (
    <svg className="svg-icon" viewBox="0 0 20 20">
      <path
        fill="none"
        d="M7.629,14.566c0.125,0.125,0.291,0.188,0.456,0.188c0.164,0,0.329-0.062,0.456-0.188l8.219-8.221c0.252-0.252,0.252-0.659,0-0.911c-0.252-0.252-0.659-0.252-0.911,0l-7.764,7.763L4.152,9.267c-0.252-0.251-0.66-0.251-0.911,0c-0.252,0.252-0.252,0.66,0,0.911L7.629,14.566z"
      ></path>
    </svg>
  );
};



export const arrayDisplay = (array) => {
  return _.isArray(array)
    ? array.length > 1
      ? array.slice(0, array.length - 1).join(", ") +
      " and " +
      array[array.length - 1]
      : array
    : array;
};

export const sortArray = (array, fieldName, reverse) => {
  return reverse ? array.reverse() : _.sortBy(array, [fieldName]);
};

export const splitTo2DArray = (arr) => {
  const itemsPerRow = 2;
  return arr.reduce((acc, val, ind) => {
    const currentRow = Math.floor(ind / itemsPerRow);
    if (!acc[currentRow]) {
      acc[currentRow] = [val];
    } else {
      acc[currentRow].push(val);
    };
    return acc;
  }, []);
}

export const removeLastElement = (array) => {
  array.splice(-1, 1)
  return array
}

export const birthYearRange = (birthYear) => {
  var d = new Date();
  var currentYear = d.getFullYear();
  return (birthYear > currentYear - 120) && (birthYear <= currentYear)
}

export const formatBirthDate = (value) => {
  const months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ];
  const year = value.getFullYear();
  const date = value.getDate();
  const monthIndex = value.getMonth();
  const monthName = months[monthIndex];
  const birthdate = monthName + " " + date + ", " + year;
  return birthdate
}


export const isAllArrayElementHas = (_array, __array, prop, value) => {
  value = value.toLowerCase();
  /**
   * _array = parent array
   * __array = selected users array
   * prop : key to check
   * value : value of corresponding prop
   */
  try {
    return __array.filter(su => _.find(_array, { id: su })[prop]?.toLowerCase() === value).length === __array.length
  } catch (ex) {
    return false
  }
}
export const isAnyArrayElementHas = (_array, __array, prop, value) => {
  value = value.toLowerCase();
  try {
    return __array.filter(su => _.find(_array, { id: su })[prop]?.toLowerCase() !== value).length === __array.length
  } catch (ex) {
    return false
  }
}


export function generateRandomHash(length) {
  const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  let hash = '';

  for (let i = 0; i < length; i++) {
    const randomIndex = Math.floor(Math.random() * characters.length);
    hash += characters.charAt(randomIndex);
  }

  return hash;
}



export const formatDate = (dateString) => {
  if (!dateString) {
    return null;
  }

  const dateObject = new Date(dateString);
  const formattedDate = dateObject.toLocaleDateString('en-US', {
    year: 'numeric',
    month: 'long',
    day: 'numeric'
  });
  return formattedDate;
}

export const stringifiedArray = (input) => {
  if (!Array.isArray(input)) {
    return input;
  }
  const array = [...input];
  if (array.length === 0) {
    return '';
  } else if (array.length === 1) {
    return array[0];
  } else {
    const lastItem = array.pop();
    return `${array.join(', ')}, ${lastItem}`;
  }
}

export const isValidInteger = (value) => {
  const number = Number(value);
  return Number.isInteger(number) && number <= Number.MAX_SAFE_INTEGER && number >= 0;
};
export const fetchAndPrintPDF = async ({
  token,
  currentPracticeID,
  currentPatientId,
  currentReportId,
  currentPractice,
  protocol,
  PG3_BASE_URL,
  path,
  download
}) => {
  let __wt;
  if (!download) {
    __wt = window.open("/loading");
    window.focus();
  }

  try {
    const response = await fetch(
      `${protocol}//${currentPractice}${PG3_BASE_URL}/${path}`,
      {
        headers: {
          "Content-Type": "application/pdf",
          Authorization: token,
          "Current-Practice": currentPracticeID,
        }
      });

    if (!response.ok) {
      throw new Error("Failed to load PDF");
    }

    const blob = await response.blob();
    const url = window.URL.createObjectURL(new Blob([blob], { type: "application/pdf" }));

    if (download) {
      const link = document.createElement('a');
      link.href = url;
      link.download = 'diagrams_report.pdf';
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } else if (__wt.document.location) {
      __wt.document.location.href = url;
    }
  } catch (err) {
    if (__wt) {
      __wt.close();
    }
    toastr.error("Failed to load PDF");
    console.error(err, 'Error while loading PDF');
  }
};