import React from 'react'
import Header from '../components/Admin/Header'
import { Outlet } from 'react-router-dom';
export default function AdminLayout() {
    return (
        <>
            <Header />
            {/* {children} */}
            <div id='main-content'>
                <Outlet />
            </div>
        </>
    )
}
