import { createAction } from "redux-api-middleware";

export const ADD_PATIENT_REQUEST = "ADD_PATIENT_REQUEST";
export const ADD_PATIENT_SUCCESS = "ADD_PATIENT_SUCCESS";
export const ADD_PATIENT_FAILURE = "ADD_PATIENT_FAILURE";

export const ADD_ARCHIVED_PATIENT_REQUEST = "ADD_ARCHIVED_PATIENT_REQUEST";
export const ADD_ARCHIVED_PATIENT_SUCCESS = "ADD_ARCHIVED_PATIENT_SUCCESS";
export const ADD_ARCHIVED_PATIENT_FAILURE = "ADD_ARCHIVED_PATIENT_FAILURE";

export const GET_PATIENTS_REQUEST = "GET_PATIENTS_REQUEST";
export const GET_PATIENTS_SUCCESS = "GET_PATIENTS_SUCCESS";
export const GET_PATIENTS_FAILURE = "GET_PATIENTS_FAILURE";

export const PATIENT_BATCH_FUNCTION_REQUEST = "PATIENT_BATCH_FUNCTION_REQUEST";
export const PATIENT_BATCH_FUNCTION_SUCCESS = "PATIENT_BATCH_FUNCTION_SUCCESS";
export const PATIENT_BATCH_FUNCTION_FAILURE = "PATIENT_BATCH_FUNCTION_FAILURE";

export const PATIENT_GDPR_REQUEST = "PATIENT_GDPR_REQUEST";
export const PATIENT_GDPR_REQUEST_SUCCESS = "PATIENT_GDPR_REQUEST_SUCCESS";
export const PATIENT_GDPR_REQUEST_FAILURE = "PATIENT_GDPR_REQUEST_FAILURE";


const baseUrl = process.env.REACT_APP_PG3_APP_DOMAIN;
const protocol = process.env.REACT_APP_PROTOCOL;


export const getPatients = (
  token, url
) => {
  return createAction({
    endpoint: `${baseUrl}/admin/patient_users?${url}`,
    method: "GET",
    headers: { Authorization: token, "User-Type": "Admin" },
    types: [
      GET_PATIENTS_REQUEST,
      GET_PATIENTS_SUCCESS,
      GET_PATIENTS_FAILURE,
    ],
  });
};

export const addPatient = (token, data) => {

  return createAction({
    endpoint: `${baseUrl}/admin/patient_users`,
    body: JSON.stringify(data),
    method: "POST",
    headers: { Authorization: token, "User-Type": "Admin", "Content-Type": "application/json" },
    types: [
      ADD_PATIENT_REQUEST,
      ADD_PATIENT_SUCCESS,
      ADD_PATIENT_FAILURE,
    ],
  });
};

export const addArchivedPatient = (token, data) => {

  return createAction({
    endpoint: `${baseUrl}/admin/create_archived_patient`,
    body: JSON.stringify(data),
    method: "POST",
    headers: { Authorization: token, "User-Type": "Admin", "Content-Type": "application/json" },
    types: [
      ADD_ARCHIVED_PATIENT_REQUEST,
      ADD_ARCHIVED_PATIENT_SUCCESS,
      ADD_ARCHIVED_PATIENT_FAILURE,
    ],
  });
};


export const getPatient = (token, id) => {
  return createAction({
    endpoint: `${baseUrl}/admin/patient_users/${id}`,
    method: "GET",
    headers: { Authorization: token, "User-Type": "Admin", "Content-Type": "application/json" },
    types: [
      PATIENT_BATCH_FUNCTION_REQUEST,
      PATIENT_BATCH_FUNCTION_SUCCESS,
      PATIENT_BATCH_FUNCTION_FAILURE,
    ],
  });
};

export const editPatient = (token, id, data) => {
  return createAction({
    endpoint: `${baseUrl}/admin/patient_users/${id}`,
    body: JSON.stringify(data),
    method: "PATCH",
    headers: { Authorization: token, "User-Type": "Admin", "Content-Type": "application/json" },
    types: [
      PATIENT_BATCH_FUNCTION_REQUEST,
      PATIENT_BATCH_FUNCTION_SUCCESS,
      PATIENT_BATCH_FUNCTION_FAILURE,
    ],
  });
};

export const resetPassword = (token, ids) => {
  const data = {
    ids
  };
  return createAction({
    endpoint: `${baseUrl}/admin/patient/reset_password`,
    body: JSON.stringify(data),
    method: "PATCH",
    headers: { Authorization: token, "User-Type": "Admin", "Content-Type": "application/json" },
    types: [
      PATIENT_BATCH_FUNCTION_REQUEST,
      PATIENT_BATCH_FUNCTION_SUCCESS,
      PATIENT_BATCH_FUNCTION_FAILURE,
    ],
  });
};

export const resetLockout = (token, ids) => {
  const data = {
    ids
  };
  return createAction({
    endpoint: `${baseUrl}/admin/patient/reset_lockout`,
    body: JSON.stringify(data),
    method: "PATCH",
    headers: { Authorization: token, "User-Type": "Admin", "Content-Type": "application/json" },
    types: [
      PATIENT_BATCH_FUNCTION_REQUEST,
      PATIENT_BATCH_FUNCTION_SUCCESS,
      PATIENT_BATCH_FUNCTION_FAILURE,
    ],
  });
};

export const archivePatients = (token, ids) => {
  const data = {
    ids
  };
  return createAction({
    endpoint: `${baseUrl}/admin/patient/archive`,
    body: JSON.stringify(data),
    method: "PATCH",
    headers: { Authorization: token, "User-Type": "Admin", "Content-Type": "application/json" },
    types: [
      PATIENT_BATCH_FUNCTION_REQUEST,
      PATIENT_BATCH_FUNCTION_SUCCESS,
      PATIENT_BATCH_FUNCTION_FAILURE,
    ],
  });
};

export const archivePatientsReport = (token, ids) => {
  const data = {
    ids
  };
  return createAction({
    endpoint: `${baseUrl}/admin/patient/report_archive`,
    body: JSON.stringify(data),
    method: "PATCH",
    headers: { Authorization: token, "User-Type": "Admin", "Content-Type": "application/json" },
    types: [
      PATIENT_BATCH_FUNCTION_REQUEST,
      PATIENT_BATCH_FUNCTION_SUCCESS,
      PATIENT_BATCH_FUNCTION_FAILURE,
    ],
  });
};

export const restorePatients = (token, ids) => {
  const data = {
    ids
  };
  return createAction({
    endpoint: `${baseUrl}/admin/patient/restore`,
    body: JSON.stringify(data),
    method: "PATCH",
    headers: { Authorization: token, "User-Type": "Admin", "Content-Type": "application/json" },
    types: [
      PATIENT_BATCH_FUNCTION_REQUEST,
      PATIENT_BATCH_FUNCTION_SUCCESS,
      PATIENT_BATCH_FUNCTION_FAILURE,
    ],
  });
};

export const restorePatientsReport = (token, ids) => {
  const data = {
    ids
  };
  return createAction({
    endpoint: `${baseUrl}/admin/patient/report_restore`,
    body: JSON.stringify(data),
    method: "PATCH",
    headers: { Authorization: token, "User-Type": "Admin", "Content-Type": "application/json" },
    types: [
      PATIENT_BATCH_FUNCTION_REQUEST,
      PATIENT_BATCH_FUNCTION_SUCCESS,
      PATIENT_BATCH_FUNCTION_FAILURE,
    ],
  });
};

export const sendGeneticUploadReminder = (token, ids) => {
  const data = {
    ids
  };
  return createAction({
    endpoint: `${baseUrl}/admin/patient/send_genetic_upload_reminder`,
    body: JSON.stringify(data),
    method: "POST",
    headers: { Authorization: token, "User-Type": "Admin", "Content-Type": "application/json" },
    types: [
      PATIENT_BATCH_FUNCTION_REQUEST,
      PATIENT_BATCH_FUNCTION_SUCCESS,
      PATIENT_BATCH_FUNCTION_FAILURE,
    ],
  });
};

export const sendRegistrationReminder = (token, ids) => {
  const data = {
    ids
  };
  return createAction({
    endpoint: `${baseUrl}/admin/patient/send_registration_reminder`,
    body: JSON.stringify(data),
    method: "POST",
    headers: { Authorization: token, "User-Type": "Admin", "Content-Type": "application/json" },
    types: [
      PATIENT_BATCH_FUNCTION_REQUEST,
      PATIENT_BATCH_FUNCTION_SUCCESS,
      PATIENT_BATCH_FUNCTION_FAILURE,
    ],
  });
};

export const gdprRequest = (token, id, requestType) => {
  const data = {
    id: id,
    request_type: requestType
  };
  return createAction({
    endpoint: `${baseUrl}/admin/patient/gdpr_request`,
    body: JSON.stringify(data),
    method: "POST",
    headers: { Authorization: token, "User-Type": "Admin", "Content-Type": "application/json" },
    types: [
      PATIENT_GDPR_REQUEST,
      PATIENT_GDPR_REQUEST_SUCCESS,
      PATIENT_GDPR_REQUEST_FAILURE,
    ],
  });
};

export const createViewLog = (token, data) => {
  return createAction({
    endpoint: `${baseUrl}/admin/patient/create_view_log`,
    body: JSON.stringify(data),
    method: "POST",
    headers: { Authorization: token, "User-Type": "Admin", "Content-Type": "application/json" },
    types: [
      PATIENT_GDPR_REQUEST,
      PATIENT_GDPR_REQUEST_SUCCESS,
      PATIENT_GDPR_REQUEST_FAILURE,
    ],
  });
};

